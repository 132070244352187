<template>
  <div class="important-page-c display--flex fd--column">
    <!-- 搜索 -->
    <div
      class="search-form btn-2b5a95"
    >
<div class="l-w" :style="{
        height: $store.state.global.isSearchExtend_1
          ? ''
          : $store.state.global.searchFormHeight_1 + 10 + 'px',
      }">
      <el-form
        label-position="right"
        :class="$store.state.global.isSearchExtend_1 ? '' : 'overflow--hidden'"
				:model="searchForm"
				@submit.native.prevent
      >
        <el-form-item label="考试名称:">
          <el-input 
						v-model.trim="searchForm.examname" 
						@keyup.enter.native="seach"
						clearable />
        </el-form-item>
				<el-form-item label="考试时长:">
				  <el-input 
						v-model.trim="searchForm.examinationtime" 
						@keyup.enter.native="seach"
						clearable />
				</el-form-item>
				<el-form-item label="教学点:">
				  <el-input 
						v-model.trim="searchForm.schoolorgname" 
						@keyup.enter.native="seach"
						clearable />
				</el-form-item>
				<el-form-item label="流水号:">
				  <el-input 
						v-model.trim="searchForm.id" 
						@keyup.enter.native="seach"
						clearable />
				</el-form-item>
      </el-form>
    </div>
      <div class="r-w">
      <el-button
        type="primary"
        icon="el-icon-search"
        @click="seach"
        :loading="!$store.state.global.isEndRequest"
        class="btn-2b5a95"
        >搜索</el-button
      >
			<el-button type="primary" @click="resetQuery">重置</el-button>
      <el-button
        type="primary"
        :icon="
          $store.state.global.isSearchExtend_1
            ? 'el-icon-arrow-up'
            : 'el-icon-arrow-down'
        "
        v-if="$store.state.global.searchExtend_1"
        class="btn-2b5a95"
        @click="onExtendClick('isSearchExtend_1')"
        >{{ $store.state.global.searchExtendText_1 }}</el-button
      >
    </div>

    </div>

    <!-- 按钮操作 -->
    <div
      class="Btn-w eaf4ff-btn"
      v-if="tableRes.buttonlist && tableRes.buttonlist.length"
      :style="{
        height: $store.state.global.isBtnExtend_1
          ? ''
          : $store.state.global.BtnHeight_1 + 10 + 'px',
      }"
    >
      <div
        class="btn-l"
        v-if="tableRes.buttonlist"
        :class="$store.state.global.isBtnExtend_1 ? '' : 'overflow--hidden'"
      >
        <el-button
          v-for="(item, index) in tableRes.buttonlist"
          :key="index"
          type="primary"
          @click="onClickBtn(item.buttonname)"
          >{{ item.name }}
        </el-button>
      </div>
      <div class="Btn-extend">
        <el-button
          type="primary"
          v-if="$store.state.global.btnExtend_1"
          class="btn-2b5a95"
          :icon="
            $store.state.global.isBtnExtend_1
              ? 'el-icon-arrow-up'
              : 'el-icon-arrow-down'
          "
          @click="onExtendClick('isBtnExtend_1')"
          >{{ $store.state.global.BtnExtendText_1 }}</el-button
        >
      </div>
    </div>

    <!-- 表格 -->
    <el-table
      :data="tableRes.list"
      stripe
      border
      :header-cell-style="{ background: '#044d8c', color: '#fff'}"
      ref="multipleTable"
      style="width: 100%"
      :cell-style="{padding:0}"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55" />
			<el-table-column
        label="流水号"
        prop="id"
        align="center"
      />
      <el-table-column
        label="考试名称"
        prop="examname"
        align="center"
        width="200"
      />
      <el-table-column
        label="考试时长"
        prop="examinationtime"
        align="center"
        width="80"
      />
			<el-table-column
			  label="题库"
			  prop="questionname"
			  align="center"
			/>
			<el-table-column
			  label="考试说明"
			  prop="remarks"
			  align="center"
			/>
			<el-table-column
			  label="录入人"
			  prop="insertusername"
			  align="center"
			/>
			<el-table-column
			  label="教学点"
			  prop="schoolorgname"
			  align="center"
			/>
			<el-table-column
        label="操作"
        align="center"
      >
			<template slot-scope="scope" align="center">
          <el-button @click="btnShowDetail(scope.row)" type="text">
							添加题型
					</el-button>
        </template>
			</el-table-column>
     
    </el-table>
    <!-- 分页 -->
    <el-pagination
      background
      @current-change="handleCurrentChange"
      :current-page="pageinfo.pageindex"
      :page-size="pageinfo.pagesize"
      :page-sizes="[10, 20, 30, 40, 50]"
      layout="total, prev, pager, next, jumper"
      :total="tableRes.totalcount"
    ></el-pagination>
    <!--添加，修改-->
    <el-dialog
			class="btn-2b5a95"
			width="650px"
			v-dialogDrag
			:close-on-click-modal='false'
      :title="dialogTitle"
      :visible.sync="showAddModal"
      v-if="showAddModal"
      append-to-body
    >
      <el-form 
				:model="dynamicValidateForm" 
				ref="dynamicValidateForm" 
				class="demo-dynamic form-item-w-100"
				status-icon 
				:rules="rules"
				label-width="100px"
				>
				<el-form-item prop="companyorgname" label="所属分公司">
				  <el-select
				    v-model="dynamicValidateForm.companyorgname"
				    placeholder="请选择"
				    :disabled="!$store.state.login.isMaxAuth"
				    clearable
				    @change="onChangeCompany($event, 'dynamicValidateForm')"
				  >
				    <el-option
				      v-for="item in $store.state.selectData.companyList"
				      :key="item.key"
				      :label="item.val"
				      :value="item.key"
				    >
				    </el-option>
				  </el-select>
				</el-form-item>
				<el-form-item prop="schoolorgname" label="所属教学点">
				  <el-select
				    v-model="dynamicValidateForm.schoolorgname"
				    placeholder="请选择"
				    clearable
				    @change="onChangeSchool($event, 'dynamicValidateForm')"
				  >
				    <el-option
				      v-for="item in $store.state.selectData.schoolList"
				      :key="item.key"
				      :label="item.val"
				      :value="item.key"
				    >
				    </el-option>
				  </el-select>
				</el-form-item>
				<el-form-item label="考试名称:" prop="examname">
          <el-input 
						v-model.trim="dynamicValidateForm.examname" />
        </el-form-item>
				<el-form-item label="考试时长:" prop="examinationtime">
          <el-input 
						v-model.trim="dynamicValidateForm.examinationtime" />
        </el-form-item>
				<el-form-item label="题库:" prop="questionname">
				  <el-input v-model="dynamicValidateForm.questionname" disabled />
				  <el-button
				    class="select-btn"
				    type="primary"
				    @click="
				      onSelectData(
								question_dialogresult2,
				        'showCollegenameModal',
				        {
				          schoolorgid: dynamicValidateForm.schoolorgid,
				        }
				      )
				    "
				    >选择</el-button
				  >
				</el-form-item>
				<el-form-item label="考试说明:" prop="remarks">
				  <el-input 
						v-model.trim="dynamicValidateForm.remarks" />
				</el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="submitAddOrEditForm('dynamicValidateForm')"
          :loading="!$store.state.global.isEndRequest"
          >提交
        </el-button>
      </span>
    </el-dialog>
		<!-- 添加题型 -->
    <el-dialog
      class="btn-2b5a95"
      width="70%"
			v-dialogDrag
      title="添加题型："
      :visible.sync="showDetailModal"
      v-if="showDetailModal"
			:close-on-click-modal='false'
      append-to-body
    >
    <div class="index_2 btn-2b5a95">
        <div
          class="l-w"
          :style="{
            height: $store.state.global.isSearchExtend_2
              ? ''
              : $store.state.global.searchFormHeight_2 + 10 + 'px',
          }"
        >
          <el-form
            label-position="right"
            :class="
              $store.state.global.isSearchExtend_2 ? '' : 'overflow--hidden'
            "
            :model="detailForm"
						@submit.native.prevent
          >
						<el-form-item label="题目类型:" prop="ttype">
						  <el-select 
								v-model="detailForm.ttype" 
						    @keyup.enter.native="selectSeach"
								clearable >
						    <el-option
									v-for="question in questiontypeList"
									:key="question.key"
									:label="question.val"
									:value="question.key">
								</el-option>
						  </el-select>
						</el-form-item>
          </el-form>
        </div>
        <div class="r-w">
          <el-button
            type="primary"
            icon="el-icon-search"
            :loading="!$store.state.global.isEndRequest"
            class="btn-2b5a95"
            @click="selectSeach"
            >搜索</el-button
          >
					<el-button type="primary" @click="handleQuery">重置</el-button>
          <el-button
            type="primary"
            :icon="
              $store.state.global.isSearchExtend_2
                ? 'el-icon-arrow-up'
                : 'el-icon-arrow-down'
            "
            v-if="$store.state.global.searchExtend_2"
            class="btn-2b5a95"
            @click="onExtendClick('isSearchExtend_2')"
            >{{ $store.state.global.searchExtendText_2 }}</el-button
          >
        </div>
      </div>
      <!-- 操作按钮 -->
      <div class="select-btn" style="margin-bottom: 20px;">
        <el-button type="primary" @click="btnAddRJ">新增</el-button>
				<el-button type="primary" @click="btneditDetail">修改</el-button>
        <el-button type="primary" @click="btnDelDetial">删除 </el-button>
        <el-button type="primary" @click="btnReloadDetial">刷新 </el-button>
      </div>
      <el-table
        :data="selectTable.list"
        style="width: 100%"
				stripe
				border
				:header-cell-style="{ background: '#044d8c', color: '#fff'}"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column
          label="流水号"
          prop="id"
          align="center"
        ></el-table-column>
				<el-table-column
          label="题目名称"
          prop="examname"
          align="center"
        >	
				</el-table-column>
				<el-table-column
				  label="题目类型"
				  prop="ttype"
				  align="center"
				>	
					<template slot-scope="scope">
					  {{ istype[scope.row.ttype] }}
					</template>
				</el-table-column>
				<el-table-column
				  label="单题分数"
				  prop="questionscore"
				  align="center"
				></el-table-column>
				<el-table-column
				  label="题目数量"
				  prop="questionquantity"
				  align="center"
				></el-table-column>
				<el-table-column
				  label="排序"
				  prop="sort"
				  align="center"
				></el-table-column>
      </el-table>

      <el-pagination
        background
				@current-change="
				  (val, data) => handleSelectChange(val, Object.assign({parentid: this.itemRow.id}))
				"
        :current-page="selectPageInfo.pageindex"
        :page-size="selectPageInfo.pagesize"
        :page-sizes="[10, 20, 30, 40, 50]"
        layout="total, prev, pager, next, jumper"
        :total="selectTable.totalcount"
      ></el-pagination>
    </el-dialog>
		<!-- 题型新增/修改 -->
		<el-dialog
      class="btn-2b5a95"
      width="60%"
			v-dialogDrag
			:close-on-click-modal='false'
      @close="onCloseSelect"
      :title="dialogTitle"
      :visible.sync="addShowAddModal"
      v-if="addShowAddModal"
      append-to-body
    >
			<el-form
				:model="dynamicValidateForm" 
				ref="dynamicValidateForm" 
				class="demo-dynamic form-item-w-100"
				status-icon 
				:rules="rules"
				label-width="100px"
				>
				<el-form-item label="题目类型:" prop="ttype">
				  <el-select 
						v-model="dynamicValidateForm.ttype" 
						clearable>
				    <el-option
							v-for="question in questiontypeList"
							:key="question.key"
							:label="question.val"
							:value="question.key">
						</el-option>
				  </el-select>
				</el-form-item>
				<el-form-item label="单题分值:" prop="questionscore">
			    <el-input 
						v-model.trim="dynamicValidateForm.questionscore" />
			  </el-form-item>
				<el-form-item label="题目数量:" prop="questionquantity">
			    <el-input 
						v-model.trim="dynamicValidateForm.questionquantity" />
			  </el-form-item>
				<el-form-item label="排序:" prop="sort">
				  <el-input 
						v-model.trim="dynamicValidateForm.sort" />
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
			  <el-button
			    type="primary"
			    @click="submitaddForm('dynamicValidateForm')"
			    :loading="!$store.state.global.isEndRequest"
			    >提交
			  </el-button>
			</span>
    </el-dialog>
		<!--选择题库-->
		<el-dialog
		  class="btn-2b5a95"
		  width="1200px"
			v-dialogDrag
			:close-on-click-modal='false'
		  @close="onCloseSelect"
		  title="选择题库："
		  :visible.sync="showCollegenameModal"
		  v-if="showCollegenameModal"
		  append-to-body
		>
			<el-button type="primary" @click="onConfirmquestionname">确定</el-button>
		 <div class="index_2 btn-2b5a95">
		    <div
		      class="l-w"
		      :style="{
		        height: $store.state.global.isSearchExtend_2
		          ? ''
		          : $store.state.global.searchFormHeight_2 + 10 + 'px',
		      }"
		    >
		      <el-form
		        label-position="right"
		        :class="
		          $store.state.global.isSearchExtend_2 ? '' : 'overflow--hidden'
		        "
		        :model="selectSearchForm"
						@submit.native.prevent
		      >
		        <el-form-item label="题库:" prop="questionname">
		          <el-input 
								v-model.trim="selectSearchForm.questionname" 
								@keyup.enter.native="onSelectSearchClick(selectTableUrl,{schoolorgid: dynamicValidateForm.schoolorgid}, selectSearchForm)" clearable/>
		        </el-form-item>
		      </el-form>
		    </div>
		    <div class="r-w">
		      <el-button
		        type="primary"
		        icon="el-icon-search"
		        :loading="!$store.state.global.isEndRequest"
		        class="btn-2b5a95"
						 @click="onSelectSearchClick(selectTableUrl,{schoolorgid: dynamicValidateForm.schoolorgid}, selectSearchForm)"
		        >搜索</el-button
		      >
					<el-button type="primary" @click="handleQuery">重置</el-button>
		      <el-button
		        type="primary"
		        :icon="
		          $store.state.global.isSearchExtend_2
		            ? 'el-icon-arrow-up'
		            : 'el-icon-arrow-down'
		        "
		        v-if="$store.state.global.searchExtend_2"
		        class="btn-2b5a95"
		        @click="onExtendClick('isSearchExtend_2')"
		        >{{ $store.state.global.searchExtendText_2 }}</el-button
		      >
		    </div>
		  </div>
		  <el-table
		    :data="selectTable.list"
		    style="width: 100%"
		    ref="moneyTable"
				stripe
				border
				:header-cell-style="{ background: '#044d8c', color: '#fff'}"
		    @selection-change="handleSelectionChange"
		  >
		    <el-table-column type="selection" width="55" />
		    <el-table-column label="流水号" prop="id" align="center" />
		    <el-table-column label="题库" prop="questionname" align="center" />
		  </el-table>
		  <el-pagination
				background
				@current-change="
				 (val, data) =>
				   handleSelectChange(val, Object.assign({
		              schoolorgid: dynamicValidateForm.schoolorgid,
		            }, selectSearchForm))
				"
		    :current-page="selectPageInfo.pageindex"
		    :page-size="selectPageInfo.pagesize"
		    :page-sizes="[10, 20, 30, 40, 50]"
		    layout="total, prev, pager, next, jumper"
		    :total="selectTable.totalcount"
		  ></el-pagination>
		</el-dialog>
  </div>
</template>


<script>
import { _coursetype , _ttype} from "@/assets/js/filedValueFlag";
import Export from "@/components/Export/index.vue";
import Import from "@/components/Import/index.vue";
import { part } from "@/utils/mixins";
import { mapActions } from "vuex";
import {
  examsettings_list,
	question_dialogresult2,
	examsettings_input,
	examsettings_delete,
	examsettings_save,
	examsettingsdetailed_list,
	examsettingsdetailed_save,
	examsettingsdetailed_input,
	examsettingsdetailed_delete,
	
	
	
  usergroup_save,
  usergroup_input,
  usergroup_delete,
  usergroup_show,
  
  usergroup_save2,
} from "@/request/api/allChildrenProject";
import {
  _pictureupload_save,
} from "@/request/api/public";
import { myRequest } from "@/request/index";
import {
  selectCheck,
  confirmCallBack,
  messageTip,
  createObjParams,
  formatDare,
} from "@/utils/util";

export default {
  mixins: [part],
	components: {
    Import,
  },
  props: {
    question_dialogresult2: {
      default: () => question_dialogresult2
    },
  },
  data() {
    return {
      searchForm: {},
      tableRes: {}, //列表接口
      pageinfo: {
        pageindex: 1,
        pagesize: 10,
      }, //页数查询
			questiontypeList: [],
			rules: {
				companyorgname: [{ trigger: "blur", message: "必填字段", required: true }],
				schoolorgname: [{ trigger: "blur", message: "必填字段", required: true }],
				examname: [{ trigger: "blur", message: "必填字段", required: true }],
				questionname: [{ trigger: "blur", message: "必填字段", required: true }],
				examinationtime: [{ trigger: "blur", message: "必填字段", required: true }],
				remarks: [{ trigger: "blur", message: "必填字段", required: true }],
				ttype:[{ trigger: "blur", message: "必填字段", required: true }], 
				questionscore: [{ trigger: "blur", message: "必填字段", required: true }], 
				questionquantity: [{ trigger: "blur", message: "必填字段", required: true }], 
				sort: [{ trigger: "blur", message: "必填字段", required: true }], 
			},
			multipleSelection: [], //选中的表格项
    	showAddModal: false, //添加
			addShowAddModal: false, //新增添加
    	dynamicValidateForm: {
	  	}, //表单
			showCollegenameModal: false, //题库
			myForm: {},
    	dialogTitle: "", //对话框标题
			showDetailModal: false, //详情
			showTable: {},
			videoForm: {},
			videocoursedetialModal: false,
			importSaveUrl: "",
      downloadUrl: "",
			importDialog: false, //导入框
      selectSearchForm: {}, // 选择搜索
      selectPageInfo: {
        pageindex: 1,
        pagesize: 10,
      }, // 选择框分页
      selectTableUrl: "",
			detailForm: {},
			istype: _ttype,
			rjDetailTable: {}, //流水账
			rjDetailPage: {
			  pageindex: 1,
			  pagesize: 10,
			},
    };
  },
  created() {
    this.getDataList();
		this.dic_combox({
		  list: "questiontypeList",
		  typecode: "questiontype",
		  that: this,
		});
		this.org_comboxchild(this.dynamicValidateForm.companyorgid);
  },
  methods: {
    ...mapActions("global", ["onExtendClick", "isExpand"]),
    ...mapActions("selectData", ["dic_combox", "org_comboxchild"]),
		reset() {
			this.searchForm = {
				examname: null,
				examinationtime: null,
				schoolorgname: null,
				id: null,
			}
		},
		//重置
		resetQuery() {
			this.reset();
			this.pageinfo.pageindex = 1;
			this.getDataList();
		},
		querySelect() {
			this.selectSearchForm = {
				name: null
			}
		},
		handleQuery() {
			this.querySelect();
			this.onSelectData(this.selectTableUrl, null, null)
		},
    //搜索 click
    seach() {
      this.pageinfo.pageindex = 1;
      this.getDataList();
    },
    //列表查询 api
    getDataList() {
      myRequest({
        url: examsettings_list,
        data: Object.assign(this.searchForm, this.pageinfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.tableRes = res.data.data;
        }
      });
    },
    //切换当前页
    handleCurrentChange(val) {
      this.pageinfo.pageindex = val;
      this.getDataList();
    },
    //触发所有btn方法
    onClickBtn(methodsName) {
      this.$options.methods[methodsName].call(this); //btnAdd ... （防止修改this指向）
    },
		//上传文件前 before
    beforeUpload(file) {
      this.fds.append("upfile", file); // 传文件
    },
    //上传文件 upload
    uploadFile(prop) {
      myRequest({
        method: "post",
        url: _pictureupload_save,
        data: this.fds,
      }).then((res) => {
        if (res.data.code === "200") {
          this.dynamicValidateForm[prop] = res.data.url;
        }
      });
    },
		//查看详情 click
		btnShowDetail(row) {
		 this.itemRow = row;
		 this.onSelectData(examsettingsdetailed_list, "showDetailModal", {
		   parentid: this.itemRow.id,
		 });
		},
     // 搜索
    selectSeach() {
			this.selectPageInfo.pageindex = 1;
    },
    selectQuery() {
			this.detailForm = {
        name: null,
			}
		},
		handleSelectQuery() {
			this.selectSearchForm = {
			  realname: null,
			}
			this.onSelectData(examsettingsdetailed_list, null, null);
		},
		handleQuery() {
			this.selectQuery();
			this.onSelectData(usergroup_show, null,{
        parentid: this.itemRow.id,
		    });
		},
    onCloseSelectadd() {
      this.selectPageInfo.pageindex = 1;
    },
		//确认题库 click
		onConfirmquestionname() {
		  selectCheck(this.multipleSelection, "选择", false, () => {
				this.$set(
				  this.dynamicValidateForm,
				  "questionid",
				  this.multipleSelection[0].id
				);
				this.$set(
				  this.dynamicValidateForm,
				  "questionname",
				  this.multipleSelection[0].questionname
				);
				this.showCollegenameModal = false;
				this.multipleSelection = [];
				this.querySelect();
		  });
		},
		 //查看详情的刷新 click
		btnReloadDetial() {
      this.onSelectData(usergroup_show, "showDetailModal", {
        parentid: this.itemRow.id,
      });
    },
		//切换选项表格页
		handleSelectChange(val, data) {
		  this.selectPageInfo.pageindex = val;
		  this.onSelectData(this.selectTableUrl,null,data);
			this.onCloseSelect();
		},
    //添加click
    btnAdd() {
      this.dialogTitle = "添加";
      this.dynamicValidateForm = {
        schoolorgid: this.$store.state.login.userinfo.schoolorgid,
        companyorgid: this.$store.state.login.userinfo.companyorgid,
        companyorgname: this.$store.state.login.userinfo.companyorgname,
        schoolorgname: this.$store.state.login.userinfo.schoolorgname,
      };
      this.org_comboxchild(this.dynamicValidateForm.companyorgid);
      this.showAddModal = true;
    },
    //修改click
    btnEdit() {
      this.dialogTitle = "修改";
      selectCheck(this.multipleSelection, "修改", false, () => {
        this.editRoutePage(this.multipleSelection[0].id);
      });
    },
		 //删除click
		 btnDel() {
      selectCheck(this.multipleSelection, "删除", true, () => {
        confirmCallBack({
          title: "提示",
          content: "此操作将永久删除选中的数据！",
          success: () => {
            this.delTableItem(this.multipleSelection.map((i) => i.id));
          },
          fail: () => {
            messageTip({
              message: "取消操作",
              type: "info",
            });
          },
        });
      });
    },
		btnAddRJ() {
			this.dialogTitle = "添加题库";
		  this.dynamicValidateForm = {
				examid: this.itemRow.id,
			};
			this.addShowAddModal = true;
		},
		//修改详情
		btneditDetail() {
			this.dialogTitle = "修改题库";
			selectCheck(this.multipleSelection, "修改", false, () => {
			  this.editdetialPage(this.multipleSelection[0].id);
			});
		},
		editdetialPage(id) {
		  myRequest({
		    url: examsettingsdetailed_input,
		    data: {
		      id: id,
		    },
		  }).then((res) => {
		    if (res.data.code === "200") {
					this.dynamicValidateForm = res.data.data.vexamsettingsdetailed;
					this.addShowAddModal = true;
				}
		  });
		},
		//删除 api
    delTableItem(idArr) {
      myRequest(
        {
          method: "post",
          url: examsettings_delete,
          data: this.$qs.stringify(
            {
              ids: idArr,
            },
            {
              arrayFormat: "repeat",
            }
          ),
        },
        {
          isRefTableList: true,
          that: this,
        }
      );
    },
		 //查看详情的删除 click
		 btnDelDetial() {
      selectCheck(this.multipleSelection, "删除", true, () => {
				confirmCallBack({
          title: "提示",
          content: "此操作将永久删除选中的数据！",
          success: () => {
            this.delTable(this.multipleSelection.map((i) => i.id));
          },
          fail: () => {
            messageTip({
              message: "取消操作",
              type: "info",
            });
          },
        });
      });
    },
		
		delTable(idArr) {
      myRequest(
        {
          method: "post",
          url: examsettingsdetailed_delete,
          data: this.$qs.stringify(
            {
              ids: idArr,
            },
            {
              arrayFormat: "repeat",
            }
          ),
        },
        {
          isRefTableList: true,
          that: this,
        }).then((res) => {
          if(res.data.code == "200") {
            this.onSelectData(examsettingsdetailed_list, "showDetailModal", {
              parentid: this.itemRow.id,
            });
          }
        })
         
    },
    //刷新 click
    btnReload() {
      this.getDataList();
    },
		//选项表格 - 搜索 click
		onSelectSearchClick(url, modal, data) {
		  this.selectPageInfo.pageindex = 1;
		  this.onSelectData(url, modal, data);
		},
    //选项表格 api
    onSelectData(url, modal, data) {
      this.selectTableUrl = url;
      myRequest({
        url: this.selectTableUrl,
        data: Object.assign(data || {}, this.selectPageInfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.$refs.multipleTable && this.$refs.multipleTable.clearSelection();
          this.selectTable = res.data.data;
          this[modal] = true;
        }
      });
    },
    //关闭选项框 click
    onCloseSelect(){
      this.selectPageInfo.pageindex = 1
    },
    //修改路由页 api
    editRoutePage(id) {
      myRequest({
        url: examsettings_input,
        data: {
          id: id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
					this.showAddModal = true;
					this.dynamicValidateForm = res.data.data.vexamsettings;
				}
      });
    },
		submitaddForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          delete this.videoForm.inserttime;
					myRequest({
					url: examsettingsdetailed_save,
					data: this.dynamicValidateForm,
						},
						{
			  		isRefTableList: true,
			  		that: this,

			  		modal: "addShowAddModal",
					}).then((res) => {
            if(res.data.code == "200") {
              this.onSelectData(examsettingsdetailed_list, "showDetailModal", {
                parentid: this.itemRow.id,
              });
            }
          })
        }
      });
    },
		 //提交-（添加，修改） api
		 submitHandleForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
					myRequest({
					url: videocoursedetailed_savechapter,
					data: {
						videocourseid: this.itemRow.id,
						chaptername2: this.myForm.chaptername2
					}
					},
					{
			  		isRefTableList: true,
			  		that: this,

			  		modal: "addShowAddModal",
				})
        }
      });
    },
    //提交-（添加，修改） api
    submitAddOrEditForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
					this.submitForm();
        }
      });
    },
		//提交-（添加，修改） api
		submitForm() {
			myRequest({
				method: "post",
				url: examsettings_save,
				data: this.$qs.stringify(this.dynamicValidateForm, {
					arrayFormat: 'repeat'
			}),
			},{
			  isRefTableList: true,
			  that: this,
			
			  modal: "showAddModal",
			})
		},
    //选中表格数据改变时
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
  },
};
</script>



<style lang="scss">
	
</style>